import React from 'react';
import { graphql } from 'gatsby';
import DevBanner from "../sections/developmentpage/DevBanner";
import { DevPageSection} from "../styles/DevPageStyles";
import DevIntro from "../sections/developmentpage/DevIntro";
import DevBlocks from "../sections/developmentpage/DevBlocks";
import ContactForm from "../sections/contactPage/ContactForm";
import Footer from "../components/Footer";
export default function DevelopmentPage({ data, pageContext }) {
  return (
    <DevPageSection>
      <DevBanner data={data.sanityDevBanner} pageContext={pageContext.locale} />
      <DevIntro data={data.sanityDevIntro} pageContext={pageContext.locale} />
      <div className="divider"></div>
      <DevBlocks data={data.sanityDevBlocks} pageContext={pageContext.locale} />
      <div className="divider"></div>
      <ContactForm data={data.sanityContactForm} pageContext={pageContext.locale} grid={'row'} />
      <Footer data={data.sanityFooter} pageContext={pageContext.locale}/>
    </DevPageSection>
  );
}

export const query = graphql`
  query DevelopmentQuery {
    sanityDevBanner {
      title {
        en
        fr
      }
      subtitle {
        en
        fr
      }
      image {
        asset {
          fluid(sizes: "(max-width: 2400px) 100vw, 2400px") {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityDevIntro {
      title {
          fr
          en
      }
      subtitle {
        fr
        en
      }
      description {
        en
        fr
      }
    }
    sanityDevBlocks {
      title {
        en
        fr
      }
      dev_blocks_list {
        title {
          fr
          en
        }
        text {
          fr
          en
        }
        image {
          asset {
            fluid(sizes: "(max-width: 800px) 800px") {
              ...GatsbySanityImageFluid
            }
          }
        } 
      }
    }
    sanityContactForm {
      title {
        en
        fr
      }
      description {
          en
          fr
      }
      regulations {
        en {
          _rawChildren
        }
        fr {
          _rawChildren
        }
        _rawEn(resolveReferences: {maxDepth: 100})
        _rawFr(resolveReferences: {maxDepth: 100})
      }
    }
    sanityFooter {
      phone
      email
      address_line1
      address_line2
      social_media_list {
        website
        link
      }
      footer_nav_list {
        link
        title {
          en
          fr
        }
      }
    }
  }
`;