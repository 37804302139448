import React from 'react';
import Img from 'gatsby-image';

export default function DevBanner({ data, pageContext }) {
  return (
    <>
      {data.image && (
        <section className="section-banner dev">
          <Img className={'hero-image'} fluid={data.image.asset.fluid} loading={"eager"} alt="" fadeIn={false}/>
          <div className="overlay dev"></div>
          <div className="section-banner-text">
            <span className="h5">{data.subtitle[pageContext]}</span>
            <h1>{data.title[pageContext]}</h1>
          </div>
        </section>
      )}
    </>
  );
}
